import React, { useState, useEffect, useRef } from 'react'

import styled from 'styled-components'

import Input from './input'

import Button from './button'

import { Container as PageContainer } from './styles'

import { SEND_PASSWORD_RESET_LINK } from './data-provider'

import { useMutation } from '@apollo/react-hooks'

import { isEmpty, get } from 'lodash-es'

const SuccessMessage = styled.div`
    font-size: 1.5rem;
    text-align: center;
    padding: 2rem;
    line-height: 150%;
`

const Container = styled(PageContainer)`
    min-height: 70vh;
`

const Title = styled.h1`
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    font-weight: 400;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: 5rem;
    }
`

const Form = styled.form``

export default () => {
    const [email, setEmail] = useState('')

    const [successMessage, setSuccessMessage] = useState(null)

    const [sendPasswordResetLink, { data, loading }] = useMutation(
        SEND_PASSWORD_RESET_LINK
    )

    const form = useRef({})

    const getError = () => {
        const type = get(data, 'sendPasswordResetLink.type')
        const message = get(data, 'sendPasswordResetLink.message')

        if (type === 'ERROR') {
            return { message }
        }
        return null
    }

    useEffect(() => {
        const type = get(data, 'sendPasswordResetLink.type')

        const message = get(data, 'sendPasswordResetLink.message')

        console.log({ data })

        if (type === 'SUCCESS') {
            setSuccessMessage(message)
        }
    }, [data])

    console.log({ successMessage })

    return successMessage ? (
        <Container>
            <SuccessMessage>{successMessage}</SuccessMessage>
        </Container>
    ) : (
        <Container>
            <Form
                ref={form}
                onSubmit={e => {
                    e.preventDefault()
                    if (!loading) {
                        sendPasswordResetLink({
                            variables: { email },
                        })
                    }
                }}
            >
                <Title>Reset password</Title>
                <Input
                    label="email"
                    onChange={e => setEmail(e.target.value)}
                    error={getError()}
                    value={email}
                    disabled={loading}
                />

                <Button loading={loading} disabled={isEmpty(email)}>
                    Send password reset link
                </Button>
            </Form>
        </Container>
    )
}
